// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import FeaturedMarketing from 'components/featuredMarketing';
import Services from 'components/units/unitPages/unit/services';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  .featuredMkt {
    section {
      @media ${device.laptop} {
        margin-top: 3rem;
        border-bottom: 1px solid #c6c8cc;
      }
      @media ${device.tablet} {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 1.875rem;
      }
      @media ${device.mobile} {
        margin-bottom: 1.875rem;
      }
      > :first-child {
        display: flex;
        > div {
          margin: 0;
        }
        @media ${device.laptop} {
          display: none;
        }
      }
      > :last-child {
        width: 100%;
        padding-bottom: 0;
        > :first-child {
          h1 {
            width: 41rem;
            @media ${device.laptop} {
              width: 31rem;
            }

            @media ${device.mobile} {
              width: 19rem;
              padding-bottom: 0;
            }
          }
          p {
            padding-top: 1.875rem;
            margin-left: 0;
            color: #8f9194;
          }

          @media ${device.tablet} {
            height: 11rem;
          }

          @media ${device.mobile} {
            height: auto;
            padding-top: 0;
          }
          @media ${device.mobile500} {
            margin-top: 0;
          }
        }
        > :last-child {
          height: 470px;
          > div {
            width: 100%;
          }
          @media ${device.laptop} {
            height: 100%;
          }
        }
      }
    }
  }

  .cardsCdi {
    section {
      width: 100%;
      div {
        width: 100%;
        margin-left: 0;
        @media ${device.laptop} {
          display: flex;
        }
        div {
          > ul {
            width: 100%;
            @media ${device.laptop} {
              display: flex;
              flex-direction: column;
              margin: 0;
            }
            > li {
              width: 100%;
              margin-right: 0;
              > h2 {
                font-weight: 400;
              }
              :hover {
                box-shadow: unset;
              }
              :first-child {
                margin-right: 0;
                padding: 0 15px 0 0 !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                  border-bottom: 1px solid #c6c8cc;
                  border-radius: unset;
                }
              }
              :last-child {
                margin-right: 0;
                padding: 0 0 0 15px !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                }
              }
              @media ${device.laptop} {
                height: unset;
              }
              ul {
                grid-template-columns: 1fr;
                width: 100%;
                li {
                  margin-right: 0;
                  @media ${device.laptop} {
                    width: 100%;
                    height: unset;
                  }
                  p a {
                    display: contents;
                  }
                  h3 {
                    width: 80%;
                    > a {
                      :first-child {
                        @media ${device.laptop} {
                          display: flex;
                          width: 320px;
                        }
                        @media ${device.tablet} {
                          width: 220px;
                        }
                      }
                      :last-child {
                        @media ${device.laptop} {
                          top: 0;
                          right: 0;
                        }
                      }
                    }
                    @media ${device.laptop} {
                      width: 100%;
                      position: relative;
                    }
                  }
                }
                @media ${device.laptop} {
                  display: flex;
                  flex-direction: column;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: 30px;
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => (
  <GridContainer isDesktop={isDesktop}>
    <Part gridColumn={'2 / 10'} className={'featuredMkt'}>
      <FeaturedMarketing
        markdown={getMarkdown(page, 'ckl9juoqg6xtw0a2231me56f6', true)}
        fragments={getMarkdown(page, 'ckl9juoqg6xtw0a2231me56f6')}
        breadCrumb={getMarkdown(page, 'ckl9jsrag71f80a25og35ygdg', true)}
        asset={getMarkdown(page, 'ckl9juoqg6xtw0a2231me56f6', false, true)}
        isProntoAtendimento
        noButtons
        isHomeDiagnostics
        hasNoSecBlob
        isHome
        noMapa
        containerNoBorderBottomDesk
        boxInfoWidthDesk="auto"
        boxInfoTextWidthDesk="100%"
        boxInfoParagraphImgTransformDesk="unset"
        boxInfoPaddingRImgDesk="0"
        boxInfoHasNoPaddingMob
      />
    </Part>
    <Part gridColumn={'2 / -2'} className={'cardsCdi'}>
      <Services
        markdown={getMarkdown(page, 'ckl9k8t803a1t0a845nc4hdx5', true)}
        fragments={getMarkdown(page, 'ckl9k8t803a1t0a845nc4hdx5')}
        isDiagnostico
        haveNotTitle
        titleDeskWith="100%"
        isBorderRadius
      />
    </Part>
  </GridContainer>
);

const ReadyToAttend = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  // ====== WEBCHAT ====== //
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.src =
  //     'https://hospitalsiriolibanes.my.site.com/ESWWebchatAgendamentoSi1726091868705/assets/js/bootstrap.min.js';
  //   document.body.appendChild(script);

  //   const handleScriptLoad = () => {
  //     try {
  //       window.embeddedservice_bootstrap.settings.language = 'pt_BR';
  //       window.embeddedservice_bootstrap.init(
  //         '00D41000000exwd',
  //         'Webchat_Agendamento_Sites',
  //         'https://hospitalsiriolibanes.my.site.com/ESWWebchatAgendamentoSi1726091868705',
  //         {
  //           scrt2URL: 'https://hospitalsiriolibanes.my.salesforce-scrt.com',
  //         }
  //       );
  //     } catch (err) {
  //       console.error('Error loading Embedded Messaging: ', err);
  //     }
  //   };

  //   script.addEventListener('load', handleScriptLoad);

  //   return () => {
  //     script.removeEventListener('load', handleScriptLoad);
  //     document.body.removeChild(script);

  //     [
  //       'embedded-messaging',
  //       'embeddedMessagingSiteContextFrame',
  //       'embeddedMessagingFilePreviewFrame',
  //     ].forEach(id => {
  //       const element = document.getElementById(id);
  //       if (element) {
  //         document.body.removeChild(element);
  //       }
  //     });
  //   };
  // }, []);

  const getImage = getMarkdown(page, 'ckl9juoqg6xtw0a2231me56f6', false, true);
  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckl9jqxpc6xj70a22xt64pu85" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <ReadyToAttend page={response.gcms.site.pages} />;
      }}
    />
  );
};
